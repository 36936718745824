import { Error } from './error'
import { Comment } from './comment'
import { Summary } from './summary'
import { ReadingProgramForNotification } from './reading_program'

export enum Srp {
  OFF_SRP = 0,
  OPEN_SRP = 1,
  CLOSE_SRP = 2,
}

export enum Reason {
  READABLE = 0, // 閲覧可能
  CAN_NOT_READABLE = 1, // 閲覧不可。非会員・フリー会員
  STILL_NOT_READABLE = 2, // 閲覧不可。シルバー会員
}

export enum CourseId {
  anonymous = 0,
  free = 1,
  silver = 2,
  gold = 3,
}

export enum CouserPrice {
  free = 0,
  silver = 500,
  gold = 2000,
}

export enum PayType {
  ZEUS = 0,
  YAHOO = 1,
  IAP_APPLE = 2,
  AUSP = 3,
  SUGOTOKU = 4,
  DOCOMO = 5,
  PAYPAY = 6,
  GOOGLE = 7,
  AMAZON = 8,
  GMO_CREDIT_CARD = 9,
}

export enum WithdrawType {
  CANCEL = 0,
  WITHDRAW = 1,
}

interface Course {
  id: number
  name: string
  price: string
}

interface Plan {
  id: number
  name: string
  restrict_upgrade: number
  withdraw: string
  display_name: boolean
}

export interface Pickup {
  id?: number
  title?: string
  image?: string
  comment_info?: Comment
}

/** アカウント設定の「性別」「居住地」「職業」「職種」「お気に入りのカテゴリ」で利用する共通の型 */
export interface AccountBaseType {
  id: number
  name: string
}

interface Ranks {
  finish_reading: string
  comment: string
}

export interface BizGroupCluster {
  id: number
  name: string
  total: number
  is_public: boolean
}

export interface BizGroupInfo {
  finish_reading_cnt: number
  comment_cnt: number
  ranks: Ranks | null
  user_total: number
  name: string
  icon: string
  use_biz_group_cluster: boolean
  biz_group_clusters: BizGroupCluster[]
}

export interface UserInfo {
  result?: boolean
  error?: Error
  user_id?: number
  biz_group_id?: number
  labo_id?: number
  email?: string
  name?: string
  srp_id?: string
  birth?: number
  gender?: AccountBaseType
  pref?: AccountBaseType
  job?: AccountBaseType
  role?: AccountBaseType
  course?: Omit<Course, 'price'>
  favorites?: string
  font_size?: number
  notification?: boolean
  introduction?: string
  pickup?: Pickup
  image?: string
  is_opening?: boolean
  can_search?: boolean
  is_confirmed?: boolean
  is_configured?: boolean
  pay_type?: PayType
  token?: string
  plan?: Plan
  magazine1?: number
  use_srp?: boolean
  use_school?: boolean
  use_reading_program?: boolean
  biz_group_use_srp?: number
  biz_group_name?: string
  biz_group_info?: BizGroupInfo
  is_public_stand?: boolean
  is_public_reading_data?: boolean
  next_paydate?: string
  code?: number
  message?: number
  used_free_coupon?: boolean
  biz_group_is_show_reset_payment?: boolean
  biz_group_is_show_receipt?: boolean
  biz_group_is_show_social_connection?: boolean
  slack?: Slack
  allow_send_newsletter?: boolean
  allow_send_event?: boolean
  allow_send_book_labo?: boolean
  follow?: number
  limited_time_url?: LimitedTimeUrl
  can_edit_profile?: CanEditProfile
}

export interface GetCheckResponse {
  api_key: string
  api_secret: string
  notification: number
  result: boolean
  type: boolean // 施設型の場合true
}

export interface GetCheckFacilityResponse {
  result: boolean
  api_key?: string
  api_secret?: string
}

export interface PostLoginResponse {
  result: boolean
  access_token?: string
  refresh_token?: string
  api_key?: string
  api_secret?: string
  error?: string
}

export interface PostGetSessionResponse {
  result: boolean
  user?: {
    api_key: string
    api_secret: string
    biz_group_id: string
    biz_group_name: string
    biz_use_srp: string
    course: Course
    email: string
    id: string
    labo_id: string
    name: string
    notices: number
    pay_type: string
    plan_id: string
    read_this_month: string[]
    restrict_upgrade: number
    sendid: string
    srp_id: string
    summaries: Record<number, string>
    use_school: string
    use_srp: string
    withdraw: string
  }
  access_token?: string
  refresh_token?: string
}

export interface BookPercent {
  summary_id: number
  percent: number
}

export interface GetBookPercentResponse {
  result: boolean
  book_percents: BookPercent[]
}

export interface Member {
  id: number
  name: string
  srp_id: string
  image: string
  introduction: string
  is_followed: boolean
  is_following: boolean
  is_requesting: boolean
  is_opening: boolean
  is_muting: boolean
  is_blocking: boolean
}

export interface GetFollowResponse {
  result: boolean
  members?: Member[]
  total?: number
  error?: Error
}

export interface PostFollowResponse {
  result: boolean
  requested: boolean
}

export interface DeleteFollowResponse {
  result: boolean
}

export interface PostFollowCancelResponse {
  result: boolean
}

export interface PostForgotpwdResponse {
  result: boolean
}

export interface GetUserCheckReminderResponse {
  result: boolean
  user?: UserInfo
}

export interface PostResetpwdResponse {
  result: boolean
  api_key?: string
  api_secret?: string
  access_token?: string
  refresh_token?: string
  error?: Error
}

export interface PostWithdrawResponse {
  result: boolean
  csrfToken?: string
  reasons?: Record<'01' | '03' | '08' | '09' | '10' | '99', string>
}

export interface GetUserStandResponse {
  result: boolean
  total?: number
  summaries?: {
    id: number
    title: string
    subtitle: string
    subpobooleans: number
    free: boolean
    ja: boolean
    en: boolean
    new: boolean
    percent: string
    image: string
  }[]
  is_public_stand?: boolean
  error?: Error
}

export interface GetUserPrefResponse {
  result: boolean
  prefs: AccountBaseType[]
  error?: Error
}

export interface GetUserJobResponse {
  result: boolean
  jobs: AccountBaseType[]
  error?: Error
}

export interface GetUserRoleResponse {
  result: boolean
  roles: AccountBaseType[]
  error?: Error
}

export interface GetUserFavoriteResponse {
  result: boolean
  favorites: AccountBaseType[]
  error?: Error
}

export interface PostUserInfoResponse {
  result: boolean
  token?: string
  error?: Error
}

export interface PostUserInfoRequest {
  introduction: string
  pickupId: number
  isOpening: boolean
  canSearch: boolean
  isConfigured?: boolean
  isConfirmed?: boolean
  isPublicStand: boolean
  isPublicReadingData: boolean
  email: string
  password: string
  name: string
  srpId: string
  birth: number
  genderId: number
  prefId: number
  jobId: number
  roleId: number
  favorites: string
  magazine1: number
  token: string
  csrfToken: string
  allowSendNewsletter?: boolean
  allowSendEvent?: boolean
  allowSendBookLabo?: boolean
}

export const isAccountBaseTypeList = (
  // eslint-disable-next-line
  item: any[]
): item is AccountBaseType[] => {
  return item !== undefined && item[0]?.id && item[0]?.name
}

export interface GetUserHeaderResponse {
  result: boolean
  error?: Error
  user_id?: number
  name?: string
  srp_id?: string
  course?: number
  image?: string
  plan?: number
  biz_group_id?: number
  labo_id?: number
  monthly_read?: number
  sum_read?: number
  follow?: number
  follower?: number
}

export interface GetCheckValidationResponse {
  result: boolean
  message?: string
  error?: Error
}

export interface PostEditPickupResponse {
  result: boolean
  error?: Error
}

export enum NotificationType {
  // FOLLOW_REQUEST = 1, // フォローリクエスト ※ 利用されていない
  FOLLOW_ACCEPT = 2, // フォローリクエスト許可
  NICE = 3, // 自分のコメントに対するなるほど通知
  // COMMENT_OPEN = 4, // 学びメモの公開通知 ※ 利用されていない
  RECOMMEND = 5, // おすすめ
  // RECOMMEND_REACTION = 6, // おすすめに対するいいね ※ 利用されていない
  FOLLOWED = 7, // フォローされました
  ACTION_HEARTEYES = 8,
  ACTION_JOY = 9,
  ACTION_FIRE = 10,
  ACTION_CLAP = 11,
  ACTION_CRY = 12,
  ACTION_OPENMOUSE = 13,
  BIZ_GROUP_CLUSTER_PUBLISH = 14, // クラスター公開
}

interface NotificationRecommend {
  recommend_id: number
  summary_id: number
  title: string
  comment: string
  summary_image: string
}

export interface Notification {
  notification_id: number
  option_id: number
  type: NotificationType
  member: Member
  comment: Comment
  recommend: NotificationRecommend
  is_appreciating: boolean
  elapsed: string
}

interface FollowRequestUser {
  name: string
  image: string
}
export interface NotificationFollowRequest {
  user1: FollowRequestUser
  user2: FollowRequestUser
  request_count: number
}

export interface GetUserNotificationResponse {
  result: boolean
  error?: Error
  new?: number
  notification?: Notification[]
  follow_request?: NotificationFollowRequest
  biz_notification?: {
    notification_name: string
    unread_count: number
    new_count: number
  }
  total?: number
}

export enum BizNotificationType {
  SUMMARY = 0, // 要約おすすめ
  READING_PROGRAM = 1, // 読書プログラムおすすめ
}

export interface BizNotification {
  name: string
  body: string
  type: number
  summary?: Summary
  reading_program?: ReadingProgramForNotification
  user_notice_id: number
  referred: {
    Time: string
    Valid: boolean
  }
}

export interface GetUserBizNotificationResponse {
  result: boolean
  error?: Error
  total?: number
  notification?: {
    biz_notification: BizNotification
    created: string
    elapsed: string
  }[]
}

export interface PostUserBizNotificationResponse {
  result: boolean
  error?: Error
}

export interface PostUserFollowAcceptResponse {
  result: boolean
  error?: Error
}

export interface PostUserFollowRejectResponse {
  result: boolean
  error?: Error
}

interface FollowRequestMember extends Omit<Member, 'id'> {
  follow: number
  follower: number
  monthly_read: number
  sum_read: number
  user_id: number
}

interface FollowRequest {
  member: FollowRequestMember
  created: string
  elapsed: string
}

export interface GetUserFollowRequestResponse {
  result: boolean
  error?: Error
  total?: number
  requests?: FollowRequest[]
}

export interface GetUserHistoryResponse {
  result: boolean
  error?: Error
  free?: {
    read: { summaries: Summary[] }
    reading: { summaries: Summary[] }
  }
  paid?: {
    read: { summaries: Summary[] }
    reading: { summaries: Summary[] }
  }
}

export interface PostUserHistoryResponse {
  result: boolean
  error?: Error
}

export interface PostUserProfileImageResponse {
  result: boolean
}

export interface PostUserRegistRequest {
  email: string
  password: string
  name: string
  birth: number
  genderId: number
  prefId: number
  jobId: number
  roleId: number
  fontSize: number
  favorites: string
  referrer: string
  userReferrer: string
  cid?: string
  sid?: string
}

export const initialPostUserRegistValues = {
  email: '',
  password: '',
  name: '',
  birth: 0,
  genderId: 2,
  prefId: 0,
  jobId: 0,
  roleId: 0,
  fontSize: 16,
  favorites: '',
  referrer: '',
  userReferrer: '',
}
export interface PostUserRegistResponse {
  result: boolean
  error?: Error
  user?: {
    id?: number
    access_token?: string
    refresh_token?: string
    api_key?: string
    api_secret?: string
    expires?: string
    notification?: boolean
  }
}

export interface UserOptionSession {
  email?: string
  sid?: string
  cid?: string
}

export interface Slack {
  team_id: string
}

/** 個人・法人のニュースレター設定で利用する共通の型 */
export interface NewsletterBaseType {
  allow_send_newsletter?: boolean
  allow_send_event?: boolean
  allow_send_book_labo?: boolean
}

export const couserPriceById = {
  [CourseId.free]: CouserPrice.free,
  [CourseId.silver]: CouserPrice.silver,
  [CourseId.gold]: CouserPrice.gold,
}

export interface LimitedTimeUrl {
  nt_session_id: string
}

export interface PostSmartAuthResponse {
  result: boolean
  error?: Error
}

export interface PostSetuppwdResponse {
  result: boolean
  api_key?: string
  api_secret?: string
  access_token?: string
  refresh_token?: string
  error?: Error
}

export interface CanEditProfile {
  can_edit_email: boolean
  can_edit_password: boolean
  can_edit_srp_id: boolean
  can_edit_birth: boolean
  can_edit_gender: boolean
  can_edit_pref: boolean
  can_edit_job: boolean
  can_edit_role: boolean
  can_edit_favorites: boolean
  can_edit_newsletter_setting: boolean
}
